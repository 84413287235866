<script>
import draggable from 'vuedraggable'
import moment from "moment-timezone";
import CardInRow from '@/components/Lego/CardInRow.vue';
import CheckButton from '@/components/Lego/CheckButton.vue';
import Tooltip2Vue from '@/components/Tooltip2Vue/Tooltip2Vue';
import LegoBtn from '@/components/Lego/LegoBtn.vue';
import AddBtn from '@/components/buttons/AddBtn.vue';
import TableroGanttHeader from '@/components/Lego/TableroGanttHeader.vue'

export default {
  components: {
    draggable,
    CardInRow,
    CheckButton,
    Tooltip2Vue,
    LegoBtn,
    AddBtn,
    TableroGanttHeader
  },
  data() {
    return {
      projectInitTime: null,
      ganttTime: [],
      samples: 40,
      period: { name: 'Día', days: 1, samples: 40 },
      optionPeriod: [
        { name: 'Día', days: 1, samples: 40 },
        { name: 'Semana', days: 7, samples: 20 },
        { name: 'Mese', days: 30, samples: 13 },
        { name: 'Trimestre', days: 90, samples: 4 },
        { name: 'Cuatrimestre', days: 120, samples: 3 },
      ],
      keyDownCtrl: false,
      heads: [
        { title: 'C', name: 'color', size: '10px', show: true },
        { title: 'Tareas', name: 'title', size: '1fr', show: true },
        { title: 'Term.', name: 'isDone', size: '50px', show: true },
        { title: 'Resp.', name: 'assignments', size: '50px', show: true },
        { title: 'Fechas', name: 'dates', size: '150px', show: true },
        { title: 'Dias', name: 'days', size: '50px', show: true },
        { title: 'Gantt', name: 'gantt', size: '3fr', show: true }
      ],
      filterBySearchTitle: '',
      filterByResponsable: null
    }
  },
  destroyed() {
    document.removeEventListener('keydown', this.handleKeyDown)
    document.removeEventListener('keyup', this.handleKeyUp)
  },
  mounted() {
    // const date = new Date(this.board.createdAt)
    const date = new Date()
    this.projectInitTime = moment(date).tz('America/Bogota').format('YYYY-MM-DD')
    this.setCronograma()

    this.$watch(() => this.projectInitTime, this.setCronograma)
    this.$watch(() => this.samples, this.setCronograma)

    document.addEventListener('keydown', this.handleKeyDown)
    document.addEventListener('keyup', this.handleKeyUp)
  },
  computed: {
    board() {
      return this.$store.state.lego.board
    },
    columns() {
      return this.board.columns
    },
    groupsFiltered() {
      const columns =  this.columns.map( column => {
        return {
          ...column,
          cards: column.cards.filter(card => {
            const responsables = card.assignments.map(assignment => assignment.personaId)
            const evaluationTitle = card.title.toLowerCase().includes(this.filterBySearchTitle)
            const evaluationResponsable = this.filterByResponsable ? responsables.includes(this.filterByResponsable) : true
            return evaluationTitle  && evaluationResponsable
          })
        }
      })

      return columns.filter(column => column.cards.length > 0)
    },
    calcSizeCols() {
      return this.heads.filter(h => h.show).map(h => h.size).join(' ')
    }


  },
  methods: {
    handleKeyDown(ev) {
      if (ev.keyCode == 17) {
        this.keyDownCtrl = true
      }
    },
    handleKeyUp(ev) {
      if (ev.keyCode == 17) {
        this.keyDownCtrl = false
      }
    },

    setCronograma() {
      const date = new Date(this.projectInitTime)
      this.ganttTime = []
      for (let i = 0; i < this.samples; i++) {
        const numDays = i * this.period.days
        this.ganttTime.push(this.dayFormat(new Date(date), numDays))
      }
    },
    setPeriod(period) {
      this.period = period
      this.samples = period.samples
      this.setCronograma()
    },
    createColumn(column) {
      // Seria bueno agregar un loader congelando la vista hasta que se cree la columna.
      column.LegoBoardId = this.boardId
      this.$store.dispatch('lego/addColumn', column)
    },
    setTitle(ev, column) {
      const title = ev.target.value.trim()
      if (!title) return
      this.$store.dispatch('lego/setItemInColumn', {
        columnId: column.id,
        obj: { title }
      })
      ev.target.blur()
    },
    setColor(ev, column) {
      const color = ev.target.value.trim()
      if (!color) return
      this.$store.dispatch('lego/setItemInColumn', {
        columnId: column.id,
        obj: { color }
      })
    },
    onEndColumn(ev) {
      const { oldIndex, newIndex } = ev;
      let newColumns = [...this.columns]; // Clona la lista para evitar mutaciones directas
      const movedItem = newColumns.splice(oldIndex, 1)[0];
      newColumns.splice(newIndex, 0, movedItem);
      this.$store.dispatch('lego/setColumns', newColumns)
    },
    deleteColumn(column) {
      this.$store.dispatch('lego/deleteColumn', column)
    },
    createCard(card, column) {
      card.LegoColumnId = column.id
      this.$store.dispatch('lego/addCard', card)
    },

    dayFormat(date, days = 0) {
      date.setDate(date.getDate() + days)
      // day las dos primeras letras del dia
      return {
        date: moment(date).tz('America/Bogota').format('DD-MMM-YY'),
        month: moment(date).tz('America/Bogota').format('MMM'),
        day: moment(date).tz('America/Bogota').format('DD-MMM-YY').slice(0, 2),
        dateNumber: date
      }
    },
    headerDate(date) {
      let halfDayOfMonth = this.ganttTime.filter(gTime => gTime.month == date.month)
      const firstDayOfMonth = halfDayOfMonth[Math.floor(halfDayOfMonth.length / 2)]
      if (firstDayOfMonth.date == date.date) {
        return date.month
      } else
        return ''
    },
    handleScrollDate(ev) {
      ev.preventDefault()
      let direction
      if (ev.deltaY > 0 || ev.deltaX > 0) {
        direction = this.period.days + 1
      } else {
        direction = -this.period.days
      }

      if (!this.keyDownCtrl) {
        const date = new Date(this.projectInitTime)
        const newDate = new Date(date.setDate(date.getDate() + direction))
        const withFormateDate = moment(newDate).tz('America/Bogota').format('YYYY-MM-DD')
        this.projectInitTime = withFormateDate
      } else {
        this.samples += direction
      }

    }
  }
}
</script>

<template>
  <div class="board">

    <div class="gantt-header">
      <div class="gantt-header-controls ">
        <Tooltip2Vue position="bottom-start">
          <LegoBtn>
            Controles
          </LegoBtn>
          <template #tooltip="{ close }">
            <div class="controls-tp">
              <header class="controls-tp--header">
                <span>
                  Controles
                </span>
                <AddBtn @click="close" class="close" />
              </header>
              <div>
                <div v-for="(head, idx) in heads" :key="'Control' + head.title" class="control-checkbox">
                  <span>
                    {{ head.title }}
                  </span>
                  <CheckButton lite v-model="heads[idx].show" />
                </div>
              </div>
            </div>
          </template>
        </Tooltip2Vue>
  
        <Tooltip2Vue position="bottom-end" >
          <LegoBtn>
            Control de tiempo
          </LegoBtn>
          <template #tooltip="{close}">
            <div class="controls-tp">
              <header class="controls-tp--header">
                <span>
                  Control de tiempo
                </span>
                <AddBtn @click="close" class="close" />
              </header>
              <div class="wrapper-lego-input">
                <label for="">Fecha de inicio</label>
                <input class="lego-input" type="date" v-model="projectInitTime" />
              </div>
              <div class="wrapper-lego-input">
                <label for="">Muestras</label>
                <input class="lego-input" type="number" v-model="samples">
              </div>
              <div class="wrapper-lego-input">
                <label for="">Periodo</label>
                <v-select class="lego" :options="optionPeriod" :get-option-label="(option) => option.name" :value="period"
                  @input="setPeriod"></v-select>
              </div>
            </div>
          </template>
        </Tooltip2Vue>
      </div>


      <div class="group-task">
        <div class="group-task--title"></div>
        <TableroGanttHeader
        @filterByResponsable="filterByResponsable = $event"
        @filterBySearchTitle="filterBySearchTitle = $event"
        @wheel="handleScrollDate"
        :heads="heads.filter(h => h.show)"
        :ganttTime="ganttTime"
        :samples="samples"
        :optionsCollaborators="board.collaborators"
        class="gantt-row group-task--body" />
      </div>



    </div>

    <draggable class="wrapper-columns" :group="{ name: 'columns' }" animation="300" :columns="columns" @end="onEndColumn"
      handle=".drag-handle">
      <div v-for="aSection in groupsFiltered" :key="aSection.id" class="group-task">
        <div class="group-task--title _group-task--title">
          {{ aSection.title }}
        </div>
        <div class="group-task--body">
          <div v-for="task in aSection.cards" :key="task.id" >
            <CardInRow :task="{ ...task, color: aSection.color }" :ganttTime="ganttTime"
              :columnsToShow="heads.filter(h => h.show)" />
            <CardInRow v-for="subTask in task.dependents" :key="subTask.id"
              :task="{ ...subTask, color: aSection.color + '70' }" :ganttTime="ganttTime" isDependent
              :columnsToShow="heads.filter(h => h.show)" />
          </div>
        </div>
      </div>
    </draggable>
  </div>
</template>

<style scoped>
.group-task {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
.group-task--title {
  /* vertical title */
  /* writing-mode: vertical-rl; */
  /* text-orientation: mixed;  */
  /* width: 20px; */
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
._group-task--title {
  border: solid 1px rgba(255, 255, 255, 0.1);
  padding: 0.25rem;
}

.group-task--body {
  width: 100%;
}


.controls-tp {
  border: solid 1px rgba(255, 255, 255, 0.25);
  box-shadow: 0 0 10px 2px rgba(0, 0, 0, 0.8);
  border-radius: 8px;
  width: 250px;
  text-align: left;
  
}

.controls-tp .control-checkbox {
  display: flex;
  justify-content: space-between;
  padding: 0.25rem 0.5rem;
  transition: 0.3s;
}

.controls-tp .wrapper-lego-input {
  padding: 0.5rem;
}

.controls-tp--header {
  padding: 50px;
  padding: 0.5rem 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  background-color: var(--lego-dbg);
  border-radius: 11px 11px 0 0;
}

.header {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 1rem;
  padding-bottom: 1rem;
}

.inDays {
  background-color: var(--lego-success);
}

.board {
  padding: 1rem;
  flex: 1;
  flex-direction: column;
  overflow: auto;
  display: flex;
  /* gap: 1rem; */
}

div.board {
  padding: 0rem;
  width: 100%;
  height: 100%;
  display: flex;
  overflow: auto;
  /* gap: 1rem; */
}

.wrapper-columns {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}


.gantt-row {
  grid-template-columns: v-bind(calcSizeCols);
  display: grid;
  text-align: center;
  color: silver;
  /* font-size: .95rem; */

}

.gantt-header {
  color: silver;
  top: 0rem;
  position: sticky;
  z-index: 2;
}

.gantt-header-controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
}



.gantt-header {
  background-color: var(--lego-dbg);
  padding: 1rem 1rem 0.5rem;
}
.wrapper-columns {
  background-color: var(--lego-dbg);
  padding: 0 1rem 1rem ;
} 


.wrapper-columns>* {
  width: 100%;
}

.grid-span-3 {
  grid-column-start: 2;
  grid-column-end: span 3;
  text-align: start;
  padding-left: 1rem;
  display: flex;
  align-items: center;
}

.grid-span-2,
:deep(.grid-span-2) {
  grid-column-start: 7;
  grid-column-end: span 2;
  text-align: start;
  display: flex;
  align-items: center;

}


.gantt-col {
  display: flex;
  align-items: flex-end;
  justify-content: center;
  height: 100%;
}




.gantt-col-date,
:deep(.gantt-col-date) {
  display: grid;
  grid-template-columns: repeat(v-bind(samples), 1fr);
  /* se ubican en la parte inferior */
  /* padding: 0.25rem 0; */

}

.gantt-col-date {
  border: solid 2px rgba(0, 0, 0, 0.171);
}
.dark .gantt-col-date {
  border: solid 2px rgba(255, 255, 255, 0.05);
}



.wrapper-columns .gantt-col-date {
  grid-template-rows: 30px;
}

.gantt-col-date>div {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: .4rem;
  font-size: .75rem;
}

.gantt-col-date .day {

  width: 100%;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.178);
}
.dark .gantt-col-date .day {

  width: 100%;
  box-shadow: 0 0 0px 1px rgba(255, 255, 255, 0.05);
}

.first-day {
  border-left: solid 1px rgba(0, 0, 0, 0.253);
}
.dark .first-day {
  border-left: solid 1px rgba(255, 255, 255, 0.05);
}
</style>
